import React, { useContext } from 'react';
import {
  Box,
  Flex,
  Button,
  Spacer,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { WebAppContext } from '../contexts/WebAppContext';
import { CsrfContext } from '../contexts/CsrfSyncContext';
import "../styles/components/NavigationHeader.css";

const NavigationHeader = () => {
  const navigate = useNavigate();
  const { webAppInfo } = useContext(WebAppContext);
  const { csrfToken } = useContext(CsrfContext);
  const logoUrl = webAppInfo?.branding?.logoUrl;

  const isMobile = useBreakpointValue({ base: true, md: false });

  // Define handleNavigate function
  const handleNavigate = (path) => {
    navigate(path);
  };

  // Frontend request to the logout endpoint
  const handleLogout = async () => {
    const uuid = localStorage.getItem("UUID");

    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/web/auth/logout`, {
        method: "POST",
        credentials: "include",
        headers: {
          "x-csrf-token": csrfToken,
        },
      });
    } catch (error) {
      console.error("Error logging out:", error);
    }

    sessionStorage.clear();
    window.location.href = uuid ? `/${uuid}` : "/";
  };

  return (
    <Box className="navigation-header">
      <Flex className="nav-flex-container" direction={isMobile ? "column" : "row"} align="center">
        <Flex className="logo-section" direction={isMobile ? "column" : "row"} align="center">
          {logoUrl && <Image src={logoUrl} alt="Logo" className="logo-image" />}
          <Heading as="h1" size="md" className="header-title">
            {webAppInfo?.branding?.headerText || 'App Name'}
          </Heading>
        </Flex>

        <Spacer display={isMobile ? "none" : "block"} />

        {!isMobile && (
          <Flex className="desktop-menu">
            <Button className="nav-button" onClick={() => handleNavigate('/dashboard')}>
              Dashboard
            </Button>
          </Flex>
        )}

        <Menu>
          <MenuButton as={Button} className="logout-button">
            <FaSignOutAlt size="1em" />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleLogout} icon={<FaSignOutAlt />} className="menu-logout-item">
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};

export default NavigationHeader;
