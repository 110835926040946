import { useState, useEffect, useCallback } from 'react';
import { useToast } from '@chakra-ui/react'; 
import API from '../api/axiosConfig';

const useAppointmentTypes = () => {
    const [appointmentTypes, setAppointmentTypes] = useState(() => {
        const storedTypes = sessionStorage.getItem('appointmentTypes');
        return storedTypes ? JSON.parse(storedTypes) : [];
    });
    const [isLoading, setIsLoading] = useState(() => {
        const storedTypes = sessionStorage.getItem('appointmentTypes');
        return storedTypes ? false : true;
    });
    const [error, setError] = useState(null);
    const toast = useToast(); 

    // Wrap fetchAppointmentTypes in useCallback to ensure a stable reference
    const fetchAppointmentTypes = useCallback(async () => {
        console.log("Fetching appointment types...");
        if (sessionStorage.getItem('appointmentTypes')) {
            setIsLoading(false);
            console.log("Using cached appointment types.");
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const response = await API.get('/appointments/web/types');
            console.log("Raw API Response:", response.data);

            const transformedData = transformAppointmentData(response.data);
            console.log("Transformed Appointment Data:", transformedData);

            setAppointmentTypes(transformedData);
            sessionStorage.setItem('appointmentTypes', JSON.stringify(transformedData));
            console.log("Stored appointment types in sessionStorage:", JSON.parse(sessionStorage.getItem('appointmentTypes')));
        } catch (err) {
            if (err.response && err.response.status === 403) {
                console.error('403 Forbidden: Session expired or unauthorized access');
                toast({
                    title: "Session Expired",
                    description: "Your session has expired. Please log in again.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });

                const uuid = localStorage.getItem('UUID');
                window.location.href = uuid ? `/${uuid}` : "/";
            } else {
                setError(err);
                console.error('Error fetching appointment types:', err);
            }
        } finally {
            setIsLoading(false);
        }
    }, [toast]);

    useEffect(() => {
        fetchAppointmentTypes();
    }, [fetchAppointmentTypes]);

    return { appointmentTypes, isLoading, error, fetchAppointmentTypes };
};

// Helper function to transform the appointment data
const transformAppointmentData = (data) => {
    const result = Object.keys(data).map((categoryKey) => ({
        category: categoryKey,
        types: data[categoryKey].map((type) => ({
            typeName: type.typeName,
            token: type.token,
        })),
    }));

    console.log("Transformed Result:", result);
    return result;
};

export default useAppointmentTypes;
