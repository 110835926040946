import React, { useEffect, useState } from 'react';
import { Box, Text, VStack, HStack, Heading, Button, Center, Link, Spinner } from '@chakra-ui/react';
import API from '../api/axiosConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaApple, FaGoogle } from 'react-icons/fa';
import ICalendarLink from "react-icalendar-link";
import '../styles/pages/ThankYouPage.css'; // Import the external CSS file

const ThankYouPage = () => {
    const [calendarInfo, setCalendarInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const rescheduleSuccess = location.state?.rescheduleSuccess;

    useEffect(() => {
        const fetchCalendarInfo = async () => {
            const bookingResponse = location.state?.bookingResponse;
            if (!bookingResponse) {
                navigate('/dashboard');
                return;
            }
            try {
                const response = await API.post('/appointments/web/calendar/info', {
                    data: {
                        _rrd: {
                            _err: bookingResponse._ad._err,
                            _irr: bookingResponse._ad._irr,
                            _tag: bookingResponse._ad._tag
                        }
                    }
                });

                setCalendarInfo(response.data.calendar);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch calendar info.');
                setLoading(false);
            }
        };

        fetchCalendarInfo();
    }, [location, navigate]);

    if (loading) {
        return (
            <Center className="loading-center">
                <Spinner size="xl" />
                <Text className="loading-text">Fetching appointment details...</Text>
            </Center>
        );
    }

    if (error) {
        return (
            <Center className="error-center">
                <Text className="error-text">{error}</Text>
            </Center>
        );
    }

    const extractDateTime = (dateString) => {
        const match = dateString.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/);
        if (!match) return null;
        const [, year, month, day, hours, minutes] = match.map(Number);
        return { year, month: month - 1, day, hours, minutes };  // Note: months are 0-indexed in JS Date
    };

    const startDateComponents = extractDateTime(calendarInfo.apple.startDate);
    const endDateComponents = extractDateTime(calendarInfo.apple.endDate);

    const startDate = new Date(startDateComponents.year, startDateComponents.month, startDateComponents.day, startDateComponents.hours, startDateComponents.minutes);
    const endDate = new Date(endDateComponents.year, endDateComponents.month, endDateComponents.day, endDateComponents.hours, endDateComponents.minutes);

    const appleEvent = calendarInfo ? {
        title: calendarInfo.apple.title,
        description: calendarInfo.apple.description,
        location: calendarInfo.apple.location,
        startTime: startDate.toISOString(),
        endTime: endDate.toISOString()
    } : null;

    const googleStartDate = startDate.toISOString().replace(/-|:|\.\d\d\d/g, "");
    const googleEndDate = endDate.toISOString().replace(/-|:|\.\d\d\d/g, "");

    return (
        <Center className="thank-you-center">
            <Box className="thank-you-box">
                {rescheduleSuccess ? (
                <Heading as="h1" size="xl" mb={4}>Appointment Rescheduled</Heading>
                ) : (
                    <Heading as="h1" size="xl" mb={4}>Appointment Booked</Heading>
                )}
                {calendarInfo && (
                    <VStack spacing={4} align="stretch">
                        <Box>
                            <Text className="calendar-description">{calendarInfo.apple.description}</Text>
                        </Box>
                        <Box mt={4}>
                            <HStack justify="flex-end" spacing={4}>
                                <Button colorScheme="orange" onClick={() => navigate('/dashboard')}>
                                    Back to Dashboard
                                </Button>
                                <ICalendarLink event={appleEvent}>
                                    <Button colorScheme="blue" leftIcon={<FaApple />}>
                                        Apple
                                    </Button>
                                </ICalendarLink>
                                <Button
                                    as={Link}
                                    href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(calendarInfo.google.title)}&dates=${googleStartDate}/${googleEndDate}&details=${encodeURIComponent(calendarInfo.google.details)}&location=${encodeURIComponent(calendarInfo.google.location)}`}
                                    colorScheme="green"
                                    leftIcon={<FaGoogle />}
                                    isExternal
                                >
                                    Google
                                </Button>
                            </HStack>
                        </Box>
                    </VStack>
                )}
            </Box>
        </Center>
    );
};

export default ThankYouPage;
