import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import API from '../api/axiosConfig';

const useBookAppointment = () => {
    const [bookingError, setBookingError] = useState(null);
    const [isBooking, setIsBooking] = useState(false);
    const toast = useToast();

    const bookAppointment = async (_pos, date, time, _at, isReschedule, isPlanned, AptData) => {
        setIsBooking(true);
        setBookingError(null);

        const appointmentDateTime = `${date} ${time}`;
        try {
            const payload = {
                aptdatetime: appointmentDateTime,
                isReschedule: isReschedule,
                isPlanned: isPlanned,
                _pos: _pos
            };

            if (isReschedule) {
                console.log("Rescheduling appointment:", payload);
                const response = await API.post('/appointments/web/change', { reschedule: AptData, AptDateTime: appointmentDateTime });
                return response.data;
            } else {
                if (!isPlanned) {
                    payload._at = _at;
                } else {
                    payload.AptData = AptData;
                }
                console.log("Booking appointment:", payload);
                const response = await API.post('/appointments/web/book', payload);
                return response.data;
            }

        } catch (error) {
            let description = "There was an error processing your request. Please try again.";

            if (error.response) {
                if (error.response.status === 403) {
                    const uuid = localStorage.getItem('UUID');
                    toast({
                        title: "Session Expired",
                        description: "Your session has expired. You will be redirected to the login page.",
                        status: "warning",
                        duration: null,
                        isClosable: true,
                    });
                    setTimeout(() => {
                        window.location.href = uuid ? `/${uuid}` : "/";
                    }, 2000);
                } else if (error.response.data?.error) {
                    description = error.response.data.error;
                }
            }

            setBookingError(description);
            throw error;
        } finally {
            setIsBooking(false);
        }
    };

    return { bookAppointment, isBooking, bookingError };
};

export default useBookAppointment;
