import React from 'react';
import { Spinner, Box, Text } from '@chakra-ui/react';
import '../styles/components/LoadingSpinner.css'; // Import the external CSS file

const LoadingSpinner = ({ message = "Loading..." }) => {
    return (
        <Box className="loading-spinner-container">
            <Spinner size="xl" />
            <Text className="loading-spinner-text">{message}</Text>
        </Box>
    );
};

export default LoadingSpinner;
