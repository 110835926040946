import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';

export const CsrfContext = createContext();

export const CsrfProvider = ({ children }) => {
  const [csrfToken, setCsrfToken] = useState('');
  const [csrfTokenFetched, setCsrfTokenFetched] = useState(false);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        // Use the correct environment variable
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/csrf-token`, { 
          withCredentials: true 
        });
        const token = response.data.token;
        setCsrfToken(token);
        setCsrfTokenFetched(true);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCsrfToken();
  }, []);

  if (!csrfTokenFetched) {
    return <LoadingSpinner />;
  }

  return (
    <CsrfContext.Provider value={{ csrfToken, setCsrfToken }}>
      {children}
    </CsrfContext.Provider>
  );
};
