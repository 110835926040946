import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { generateTempToken } from '../api/axiosConfig';
import { extractUUID } from '../utils/extractUUID';
import LoginForm from '../components/LoginForm';
import { WebAppContext } from '../contexts/WebAppContext';
import { Box } from '@chakra-ui/react';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorAlert from '../components/ErrorAlert';
import Header from '../components/Header';
import Footer from '../components/Footer';

const LoginPage = () => {
    const location = useLocation();
    const { setWebAppInfo } = useContext(WebAppContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [webAppData, setWebAppData] = useState(null);

    useEffect(() => {
        const fetchToken = async () => {
            setLoading(true);
            setError('');
            const uuid = extractUUID(location.pathname);
            localStorage.setItem('UUID', uuid);

            try {
                const response = await generateTempToken(uuid);
                sessionStorage.setItem('tempToken', response.data.tempToken);
                setWebAppInfo(response.data.webApp);
                setWebAppData(response.data.webApp);
            } catch (error) {
                console.error('Error fetching temp token:', error);
                setError('Failed to authenticate. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchToken();

        return () => {
            setLoading(false); // Cleanup to avoid setting state on unmounted component
        };
    }, [location, setWebAppInfo]);

    if (loading) {
        return (
            <Box textAlign="center" mt="20">
                <LoadingSpinner />
            </Box>
        );
    }

    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            {error && <ErrorAlert title="Authentication Error" description={error} onClose={() => setError('')} />}
            {webAppData && <Header branding={webAppData.branding} />}
            <Box flex="1" textAlign="center">
                <LoginForm />
            </Box>
            {webAppData && <Footer contactInfo={webAppData.contactInfo} legalInfo={webAppData.legalInfo} />}
        </Box>
    );
};

export default LoginPage;
