import { useState, useEffect, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import API from '../api/axiosConfig';

const useAppointments = () => {
    const [appointments, setAppointments] = useState({ past: [], upcoming: [] });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const toast = useToast();

    // Wrap fetchAppointments in useCallback for a stable reference
    const fetchAppointments = useCallback(async () => {
        try {
            const response = await API.get('/appointments/web/mine');
            setAppointments(response.data);
            setIsLoading(false);
        } catch (err) {
            if (err.response && err.response.status === 403) {
                const uuid = localStorage.getItem('UUID');
                toast({
                    title: "Session Expired",
                    description: "Your session has expired. You will be redirected to the login page.",
                    status: "warning",
                    duration: null,
                    isClosable: true,
                });
                setTimeout(() => {
                    window.location.href = uuid ? `/${uuid}` : "/";
                }, 2000);
            } else {
                setError('Failed to fetch appointments.');
                console.error('Error fetching appointments:', err);
            }
            setIsLoading(false);
        }
    }, [toast]);

    useEffect(() => {
        fetchAppointments();
    }, [fetchAppointments]);

    return { appointments, isLoading, error, fetchAppointments };
};

export default useAppointments;
