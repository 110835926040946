import React, { useEffect, useRef } from 'react';
import { Box, HStack, Tabs, TabList, TabPanels, Tab, TabPanel, Text } from '@chakra-ui/react';
import "../styles/components/AppointmentsGroup.css";

const AppointmentsGroup = ({ 
    categories = [], 
    onSelect, 
    selectedType, 
    isClickable = true, 
    selectedTabIndex = 0, 
    onTabChange, 
    compactView = false 
}) => {
    const selectedRef = useRef(null);

    useEffect(() => {
        if (selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
    }, [selectedType]);

    return (
        <Box className={`appointments-group-container ${compactView ? 'compact-view' : ''} ${!isClickable ? 'disabled' : ''}`}>
            <Text className="appointments-group-title">Appointment Types</Text>
            <Tabs 
                index={selectedTabIndex} 
                onChange={onTabChange} 
                variant="enclosed"
            >
                <TabList>
                    {categories.map((categoryItem, index) => (
                        <Tab key={index}>{categoryItem.category}</Tab>
                    ))}
                </TabList>

                <TabPanels>
                    {categories.map((categoryItem, index) => (
                        <TabPanel key={index}>
                            <HStack 
                                className={`appointments-group-list ${compactView ? 'compact-view' : ''}`} 
                                spacing={4} 
                                overflowX="auto" 
                                padding="1rem"
                            >
                                {categoryItem.types.map((appointment, idx) => (
                                    <Box
                                        key={idx}
                                        className={`type-box ${compactView ? 'compact-view' : ''} ${selectedType === appointment.token ? 'selected' : ''}`}
                                        onClick={() => onSelect(appointment.token, appointment.typeName)} // Pass both token and typeName
                                        ref={selectedType === appointment.token ? selectedRef : null}
                                    >
                                        <Text className={`type-text ${compactView ? 'compact-view' : ''}`}>{appointment.typeName}</Text>
                                    </Box>
                                ))}
                            </HStack>
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default AppointmentsGroup;
