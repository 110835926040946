import React from 'react';
import { Box, Flex, Link, Text } from '@chakra-ui/react';

const Footer = ({ contactInfo, legalInfo }) => {
    return (
        <Box bg="gray.900" color="gray.200" py={4} px={4} mt="auto">
            <Flex direction="column" maxW="100%" mx="auto">
                <Text fontSize="sm" mb={4} textAlign="justify">
                    {legalInfo.disclaimer}
                </Text>
                <Flex justify="space-between" align="flex-start" direction={{ base: "column", md: "row" }}>
                    <Text fontSize="sm">&copy; {new Date().getFullYear()} RoboReception. All rights reserved.</Text>
                    <Flex align="center" direction="row" mt={{ base: 4, md: 0 }}>
                        <Link href={contactInfo.contactUs.url} mx={2} fontSize="sm">
                            {contactInfo.contactUs.displayText}
                        </Link>
                        <Link href={contactInfo.troubleshooting.url} mx={2} fontSize="sm">
                            {contactInfo.troubleshooting.displayText}
                        </Link>
                        <Link href={legalInfo.termsPrivacy.url} mx={2} fontSize="sm">
                            {legalInfo.termsPrivacy.displayText}
                        </Link>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
};

export default Footer;
