import React, { useState, useEffect, useCallback } from 'react';
import { Box, Text, VStack, Heading, Center, HStack, IconButton, useToast } from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import useAppointmentTypes from '../hooks/useAppointmentTypes';
import useBookAppointment from '../hooks/useBookAppointment';
import AppointmentsGroup from '../components/AppointmentsGroup';
import AppointmentSlots from '../components/AppointmentSlots';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorAlert from '../components/ErrorAlert';
import BookingBreadcrumb from '../components/BookingBreadcrumb';
import API from '../api/axiosConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import "../styles/pages/BookAppointment.css";

const BookAppointment = () => {
    const { appointmentTypes, isLoading, error, fetchAppointmentTypes } = useAppointmentTypes();
    const [selectedType, setSelectedType] = useState(() => sessionStorage.getItem('selectedType'));
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [slots, setSlots] = useState([]);
    const [loadingSlots, setLoadingSlots] = useState(false);
    const [slotsError, setSlotsError] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 13)));
    const [typeName, setTypeName] = useState(null);

    const { bookAppointment, isBooking, bookingError } = useBookAppointment();
    const navigate = useNavigate();
    const toast = useToast();
    const location = useLocation();
    const selectedAppointment = location.state?.selectedAppointment;
    const isPlanned = selectedAppointment && selectedAppointment.AptStatus === "Planned";
    const isScheduledPlanned = selectedAppointment?.IsScheduledPlanned;

    const handleTabChange = () => {
        // Placeholder function: Add logic here if needed in the future
    };

    // Wrap handleSlotError in useCallback to ensure a stable reference
    const handleSlotError = useCallback((err) => {
        if (err.response && err.response.status === 403) {
            const uuid = localStorage.getItem('UUID');
            toast({
                title: "Session Expired",
                description: "Your session has expired. You will be redirected to the login page.",
                status: "warning",
                duration: null,
                isClosable: true,
            });
            setTimeout(() => {
                window.location.href = uuid ? `/${uuid}` : "/";
            }, 2000);
        } else {
            console.error("Failed to load slots:", err);
            setSlotsError('Failed to load slots. Please try again.');
            sessionStorage.removeItem('slots');
        }
    }, [toast]); // Included 'toast' in dependencies

    // Wrap fetchSlotsForPlannedAppointment in useCallback
    const fetchSlotsForPlannedAppointment = useCallback(async (isPlanned, _at, dateStart) => {
        const numberOfDays = 14;
        try {
            const response = await API.get(`/appointments/web/open/slots`, {
                params: { datestart: dateStart, numberofdays: numberOfDays, _at, isPlanned }
            });
            setSlots(response.data);
            sessionStorage.setItem('slots', JSON.stringify(response.data));
        } catch (err) {
            handleSlotError(err);
        } finally {
            setLoadingSlots(false);
        }
    }, [handleSlotError]); // 'toast' is included in handleSlotError's dependencies

    // Wrap fetchSlots in useCallback
    const fetchSlots = useCallback(async (type, dateStart) => {
        const numberOfDays = 14;
        try {
            const response = await API.get(`/appointments/web/open/slots`, {
                params: { datestart: dateStart, numberofdays: numberOfDays, _at: type }
            });
            setSlots(response.data);
            sessionStorage.setItem('slots', JSON.stringify(response.data));
        } catch (err) {
            handleSlotError(err);
        } finally {
            setLoadingSlots(false);
        }
    }, [handleSlotError]); // 'toast' is included in handleSlotError's dependencies



    // Fetch appointment types on mount
    useEffect(() => {
        if (!fetchAppointmentTypes) {
            console.error("fetchAppointmentTypes is not defined");
        } else {
            fetchAppointmentTypes();
        }
    }, [fetchAppointmentTypes]);

    // Update slots when the component mounts or when relevant data changes
    useEffect(() => {
        const savedType = sessionStorage.getItem('selectedType');
        const savedSlots = sessionStorage.getItem('slots');
        const dateStart = startDate.toISOString().split('T')[0];
        
        if (selectedAppointment) {
            if (isPlanned || isScheduledPlanned) {
                setSlots([]);
                setLoadingSlots(true);
                fetchSlotsForPlannedAppointment(true, selectedAppointment.AptData, dateStart);
            } else {
                setSelectedType(savedType || null);
                setSlots([]);
                setLoadingSlots(true);
                if (savedType) fetchSlots(savedType, dateStart);
            }
        } else if (savedType) {
            setSelectedType(savedType);
            setSlots(savedSlots ? JSON.parse(savedSlots) : []);
            setLoadingSlots(true);
            fetchSlots(savedType, dateStart);
        } else if (savedSlots) {
            setSlots(JSON.parse(savedSlots));
        }
    }, [fetchSlots, fetchSlotsForPlannedAppointment, isPlanned, isScheduledPlanned, selectedAppointment, startDate]);

    const handleSelectCategory = (category) => {
        console.log("Selected category:", category);
        setSelectedCategory(category);
        setTypeName(null);
    };

    const handleSelectType = (token, typeName) => {
        console.log(typeName, "Selected type token:", token);
        setSelectedType(token);
        setTypeName(typeName); // Store the user-friendly name of the type
        
        sessionStorage.setItem('selectedType', token);
        setSlots([]);
        setLoadingSlots(true);
        setSlotsError(null);
    
        // Find the category of the selected type and update the selected tab index
        const categoryIndex = appointmentTypes.findIndex(categoryItem =>
            categoryItem.types.some(type => type.token === token)
        );
        if (categoryIndex !== -1) {
            setSelectedTabIndex(categoryIndex);
            setSelectedCategory(appointmentTypes[categoryIndex].category);
        }
    
        const dateStart = startDate.toISOString().split('T')[0];
        fetchSlots(token, dateStart);
    };
       
    



    const handlePrevDates = () => {
        setLoadingSlots(true);
        setSlots([]);
        const newStartDate = new Date(startDate);
        newStartDate.setDate(newStartDate.getDate() - 14);
        const newEndDate = new Date(endDate);
        newEndDate.setDate(newEndDate.getDate() - 14);
        setStartDate(newStartDate);
        setEndDate(newEndDate);

        if (isPlanned || isScheduledPlanned) {
            fetchSlotsForPlannedAppointment(true, selectedAppointment.AptData, newStartDate.toISOString().split('T')[0]);
        } else {
            fetchSlots(selectedType, newStartDate.toISOString().split('T')[0]);
        }
    };

    const handleNextDates = () => {
        setLoadingSlots(true);
        setSlots([]);
        const newStartDate = new Date(startDate);
        newStartDate.setDate(newStartDate.getDate() + 14);
        const newEndDate = new Date(endDate);
        newEndDate.setDate(newEndDate.getDate() + 14);
        setStartDate(newStartDate);
        setEndDate(newEndDate);

        if (isPlanned || isScheduledPlanned) {
            fetchSlotsForPlannedAppointment(true, selectedAppointment.AptData, newStartDate.toISOString().split('T')[0]);
        } else {
            fetchSlots(selectedType, newStartDate.toISOString().split('T')[0]);
        }
    };

    const handleConfirmBooking = async (_pos, date, time) => {
        if (isBooking) return;
    
        console.log("Starting the booking or rescheduling process");
    
        let appointmentType = selectedType;
        let rescheduleSuccess = false;
        const AptData = selectedAppointment?.AptData || undefined;
    
        try {
            const isPlanned = selectedAppointment?.AptStatus === "Planned" && !selectedAppointment?.IsScheduledPlanned;
            const isScheduledPlanned = selectedAppointment?.IsScheduledPlanned;
            const isUpcoming = !isPlanned && !isScheduledPlanned && selectedAppointment?.AptStatus === "Scheduled";
            const isNewBooking = !selectedAppointment && !isPlanned && !isScheduledPlanned;
            if (isPlanned) {
                console.log("Handling Planned Appointment (New Booking):");
                await bookAppointment(_pos, date, time, appointmentType, false, true, AptData);
                rescheduleSuccess = true;
                handleToast(true, "Booking Successful", "Your planned appointment has been booked successfully.");
            } else if (isScheduledPlanned) {
                console.log("Handling Scheduled Planned Appointment (Reschedule):");
                await bookAppointment(_pos, date, time, appointmentType, true, true, AptData);
                rescheduleSuccess = true;
                handleToast(true, "Rescheduling Successful", "Your scheduled planned appointment has been rescheduled successfully.");
            } else if (isUpcoming) {
                console.log("Handling Upcoming Appointment (Reschedule):");
                await bookAppointment(_pos, date, time, appointmentType, true, false, AptData);
                rescheduleSuccess = true;
                handleToast(true, "Rescheduling Successful", "Your upcoming appointment has been rescheduled successfully.");
            } else if (isNewBooking) {
                console.log("Handling New Regular Appointment (New Booking):");
                await bookAppointment(_pos, date, time, appointmentType, false, false, AptData);
                rescheduleSuccess = true;
                handleToast(true, "Booking Successful", "Your new appointment has been booked successfully.");
            }
    
            navigate('/thank-you', { state: { rescheduleSuccess } });
        } catch (error) {
            console.error("Error during booking or rescheduling process:", error);
            handleToast(false, "Booking Failed", bookingError || "There was an error booking your appointment. Please try again.");
        }
    };

    const handleToast = (success, title, description) => {
        toast({
            title: success ? title : `${title} Failed`,
            description: success ? description : `There was an issue: ${description}`,
            status: success ? "success" : "error",
            duration: 5000,
            isClosable: true,
        });
    };


    const handleResetType = () => {
        setSelectedType(null);
        setTypeName(null); // Reset the type name in the breadcrumb
        sessionStorage.removeItem('selectedType');
        sessionStorage.removeItem('slots');
        sessionStorage.removeItem('selectedAppointment');
    };

    const handleResetCategory = () => {
        setSelectedCategory(null);
        setSelectedType(null);
        setTypeName(null);
        sessionStorage.removeItem('selectedType');
        sessionStorage.removeItem('slots');
        sessionStorage.removeItem('selectedAppointment');
    };


    const formattedStartDate = startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    const formattedEndDate = endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

    if (isLoading) return <LoadingSpinner />;
    if (error) return <ErrorAlert title="Error" description="Failed to load appointment types. Please try again." onClose={() => {}} />;

    return (
        <Box className="book-appointment-container">
            {slotsError && <ErrorAlert title="Error" description={slotsError} onClose={() => setSlotsError(null)} />}

            {!selectedType && !isPlanned && !isScheduledPlanned && !selectedCategory && (
                <Center className="select-type-heading">
                    <Heading as="h3" size="lg" fontWeight="bold">What type of care are you looking for?</Heading>
                </Center>
            )}
    
            {!selectedCategory && !isPlanned && !isScheduledPlanned && !selectedType ? (
                <>
                <BookingBreadcrumb 
                    category={selectedCategory} 
                    typeName={typeName} 
                    onResetType={handleResetType} 
                    onResetCategory={handleResetCategory} 
                />
                <VStack className="select-type-list">
                    {appointmentTypes.map((categoryItem, index) => (
                        <Box
                            key={index}
                            className="type-box"
                            onClick={() => handleSelectCategory(categoryItem.category)}
                        >
                            <Text className="type-text">{categoryItem.category}</Text>
                        </Box>
                    ))}
                </VStack>
                </>
            ) : selectedCategory && !selectedType ? (
                <>
                <BookingBreadcrumb 
                    category={selectedCategory} 
                    typeName={typeName} 
                    onResetType={handleResetType} 
                    onResetCategory={handleResetCategory} 
                />
                <VStack className="select-type-list">
                    {appointmentTypes
                        .find((item) => item.category === selectedCategory)
                        ?.types.map(({ typeName, token }, index) => (
                            <Box
                                key={index}
                                className="type-box"
                                onClick={() => handleSelectType(token, typeName)}
                            >
                                <Text className="type-text">{typeName}</Text>
                            </Box>
                        ))}
                </VStack>
                </>
            ) : (
                <Box>
                    {selectedCategory && (
                        <>
                            <BookingBreadcrumb 
                                category={selectedCategory} 
                                typeName={typeName} 
                                onResetType={handleResetType} 
                                onResetCategory={handleResetCategory} 
                            />
                            <AppointmentsGroup
                                categories={appointmentTypes}
                                onSelect={(token, typeName) => handleSelectType(token, typeName)} // Pass token and typeName
                                selectedType={selectedType}
                                selectedTabIndex={selectedTabIndex} // Pass selectedTabIndex to AppointmentsGroup
                                isClickable={!selectedAppointment}
                                onTabChange={handleTabChange} // Pass the handleTabChange function
                                compactView={true} // Enables compact styling
                            />
                        </>
                    )}
                    <HStack className="slots-header">
                        <Text>{slots.length ? `${slots.length} providers` : 'Loading providers...'}</Text>
                        <HStack>
                            <IconButton
                                icon={<FaChevronLeft />}
                                onClick={handlePrevDates}
                                isDisabled={startDate.toDateString() === new Date().toDateString()}
                                aria-label="Previous dates"
                            />
                            <Text>{`${formattedStartDate} – ${formattedEndDate}`}</Text>
                            <IconButton
                                icon={<FaChevronRight />}
                                onClick={handleNextDates}
                                aria-label="Next dates"
                            />
                        </HStack>
                    </HStack>
    
                    {loadingSlots ? (
                        <LoadingSpinner message="Loading slots..." />
                    ) : (
                        <AppointmentSlots
                            slots={slots}
                            error={slotsError}
                            startDate={startDate}
                            onConfirmBooking={handleConfirmBooking}
                            isBooking={isBooking}
                            bookingError={bookingError}
                            selectedAppointment={selectedAppointment}
                            isPlanned={isPlanned}
                        />
                    )}
                </Box>
            )}
        </Box>
    );    
};

export default BookAppointment;
