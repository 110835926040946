// /src/routes/Routes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';

// Page Components
import LoginPage from '../pages/LoginPage';
import HomePage from '../pages/HomePage';
import Dashboard from '../pages/Dashboard';
import VerifyOTP from '../pages/VerifyOTP';
import BookAppointment from '../pages/BookAppointment';
import ThankYouPage from '../pages/ThankYouPage';
import PrivateRoute from '../components/PrivateRoute';

import { Box, Flex } from '@chakra-ui/react';

// Routes Configuration
const RoutesConfig = () => (
    <Flex maxW="100%" m={0} p={0} mx="auto">
        <Box width="100%">
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/verify" element={<VerifyOTP />} />
                <Route path="/:uuid" element={<LoginPage />} /> 
                <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/book-appointment" element={<PrivateRoute><BookAppointment /></PrivateRoute>} />
                <Route path="/thank-you" element={<PrivateRoute><ThankYouPage /></PrivateRoute>} />
           
            </Routes>
        </Box>
    </Flex>
);

export default RoutesConfig;
