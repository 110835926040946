import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, VStack, HStack, Icon, Button, Divider, useDisclosure, Center, Spinner, useToast } from '@chakra-ui/react';
import { FaCalendarAlt } from 'react-icons/fa';
import ConfirmModal from './ConfirmModal';
import API from '../api/axiosConfig';
import { useNavigate } from 'react-router-dom';
import '../styles/components/AppointmentsList.css';

const AppointmentsList = ({ appointments, title, onAppointmentChange, isPastAppointment, isPlanned }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [actionType, setActionType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingAppointments, setLoadingAppointments] = useState(false);
    const [fetchAppointmentsOnClose, setFetchAppointmentsOnClose] = useState(false);
    const [cancelThresholdHours, setCancelThresholdHours] = useState(null);
    const [rescheduleThresholdHours, setRescheduleThresholdHours] = useState(null);
    const [loadingThresholds, setLoadingThresholds] = useState(true);
    const navigate = useNavigate();
    const toast = useToast();

    // Handle opening of modal for Cancel or Reschedule actions
    const handleOpenModal = (appointment, type) => {
        setSelectedAppointment(appointment);
        setActionType(type);
        onOpen();
    };

    // Handle confirmation action in modal (Cancel or Reschedule)
    const handleConfirm = async () => {
        if (actionType === 'reschedule') {
            // Navigate to the BookAppointment page with selected appointment data
            sessionStorage.setItem('selectedType', selectedAppointment.AptData); // Store encrypted appointment data

            const appointmentTypeNum = selectedAppointment.AppointmentTypeNum && selectedAppointment.AppointmentTypeNum !== 0
                ? selectedAppointment.AppointmentTypeNum
                : null;

            navigate('/book-appointment', {
                state: {
                    selectedAppointment: {
                        ...selectedAppointment,
                        AppointmentTypeNum: appointmentTypeNum, // Pass the appointment type number
                    },
                    plannedAptNum: selectedAppointment.PlannedAptNum,
                },
            });
        } else if (actionType === 'cancel') {
            // Call the cancellation API
            setIsLoading(true);
            try {
                const response = await API.post('/appointments/web/change', {
                    cancel: selectedAppointment.AptData, // Passing encrypted data
                });

                if (response.data.status === 'success') {
                    handleToast(true, "Cancellation Successful", "Your appointment has been canceled successfully.");
                    setFetchAppointmentsOnClose(true); // Set flag to fetch appointments on modal close
                    onClose(); // Close the modal after successful cancellation
                } else {
                    handleToast(false, "Cancellation Failed", "There was an issue canceling your appointment. Please try again.");
                }
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    const uuid = localStorage.getItem('UUID');
                    toast({
                        title: "Session Expired",
                        description: "Your session has expired. You will be redirected to the login page.",
                        status: "warning",
                        duration: null,
                        isClosable: true,
                    });
                    // Delay redirect to allow user to see toast
                    setTimeout(() => {
                        window.location.href = uuid ? `/${uuid}` : "/";
                    }, 2000);
                } else {
                    console.error('Error cancelling appointment:', error);
                    handleToast(false, "Cancellation Failed", "There was an issue canceling your appointment. Please try again.");
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    // Display toast notifications with success or error messages
    const handleToast = (success, title, description) => {
        toast({
            title: success ? title : `${title} Failed`,
            description: success ? description : `There was an issue: ${description}`,
            status: success ? "success" : "error",
            duration: 5000,
            isClosable: true,
        });

        console.log("Toast Notification:", { success, title, description });
    };

    // Fetch threshold values for cancel and reschedule actions
    useEffect(() => {
        const fetchThresholds = async () => {
            try {
                const response = await API.get('/appointments/web/thresholds');
                if (response.data) {
                    setCancelThresholdHours(response.data.cancelThresholdHours);
                    setRescheduleThresholdHours(response.data.rescheduleThresholdHours);
                } else {
                    console.error('Failed to fetch threshold values');
                }
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    const uuid = localStorage.getItem('UUID');
                    toast({
                        title: "Session Expired",
                        description: "Your session has expired. You will be redirected to the login page.",
                        status: "warning",
                        duration: null,
                        isClosable: true,
                    });
                    setTimeout(() => {
                        window.location.href = uuid ? `/${uuid}` : "/";
                    }, 2000);
                } else {
                    console.error('Error fetching threshold values:', error);
                }
            } finally {
                setLoadingThresholds(false);
            }
        };
    
        fetchThresholds();
    }, [toast]);    

    // Fetch updated appointments list on modal close if needed
    useEffect(() => {
        const fetchUpdatedAppointments = async () => {
            setLoadingAppointments(true);
            await onAppointmentChange(); // Fetch the updated appointments list
            setLoadingAppointments(false);
        };

        if (!isOpen && fetchAppointmentsOnClose) {
            fetchUpdatedAppointments();
            setFetchAppointmentsOnClose(false); // Reset the flag
        }

        if (!isOpen && !fetchAppointmentsOnClose) {
            setSelectedAppointment(null); // Clear the selected appointment only when not fetching
        }
    }, [isOpen, fetchAppointmentsOnClose, onAppointmentChange]);

    // Helper function to check if an appointment is within the cancel or reschedule threshold
    const isWithinThreshold = (appointmentDateTime, thresholdHours) => {
        const now = new Date();
        const appointmentTime = new Date(appointmentDateTime);
        const timeDifference = (appointmentTime - now) / (1000 * 60 * 60); // Convert time difference to hours
        return timeDifference <= thresholdHours;
    };

    return (
        <Box>
            <Heading size="md" mb={4}>{title}</Heading>
            {loadingThresholds ? (
                <Center mt={4} className="loading-center">
                    <Spinner size="xl" />
                    <Text ml={4}>Loading thresholds...</Text>
                </Center>
            ) : loadingAppointments ? (
                <Center mt={4} className="loading-center">
                    <Spinner size="xl" />
                    <Text ml={4}>Loading appointments...</Text>
                </Center>
            ) : (
                <VStack align="stretch" spacing={4}>
                    {appointments.length ? appointments.map((appointment, index) => {
                        const isPast = appointment.AptDateTime ? new Date(appointment.AptDateTime) < new Date() : false;
                        const disableCancel = cancelThresholdHours !== null && appointment.AptDateTime && isWithinThreshold(appointment.AptDateTime, cancelThresholdHours);
                        const disableReschedule = rescheduleThresholdHours !== null && appointment.AptDateTime && isWithinThreshold(appointment.AptDateTime, rescheduleThresholdHours);

                        return (
                            <Box
                                key={index}
                                className={`appointment-card ${isPast ? 'past' : 'upcoming'}`}
                            >
                                <HStack mb={2} alignItems="center">
                                    <Icon as={FaCalendarAlt} className="icon" />
                                    <Text fontSize="lg" fontWeight="bold">{appointment.AppointmentTitle}</Text>
                                </HStack>
                                <Divider mb={2} />

                                {/* Handling Planned Appointments */}
                                {isPlanned ? (
                                    <>
                                        <Text><strong>Procedures Planned:</strong> {appointment.ProcDescript || "N/A"}</Text>
                                        {appointment.Note && <Text><strong>Notes:</strong> {appointment.Note}</Text>}
                                        <Text><strong>Priority:</strong> {appointment.Priority || "Normal"}</Text>
                                        <Text><strong>Provider:</strong> {appointment.ProvFullName}</Text>
                                        <Button
                                            colorScheme="green"
                                            size="sm"
                                            mt={2}
                                            onClick={() => navigate('/book-appointment', {
                                                state: {
                                                    selectedAppointment: appointment,
                                                    plannedAptNum: appointment.PlannedAptNum,
                                                    isPlanned: true
                                                }
                                            })}
                                        >
                                            Book
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        {/* Handling Scheduled or Past Appointments */}
                                        {appointment.AptDateTime && (
                                            <>
                                                <Text><strong>Date:</strong> {new Date(appointment.AptDateTime).toLocaleDateString()}</Text>
                                                <Text><strong>Time:</strong> {new Date(appointment.AptDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Text>
                                            </>
                                        )}
                                        <Text><strong>Appointment Status:</strong> {appointment.AptStatus}</Text>
                                        <HStack className="button-group" spacing={2}>
                                            {isPastAppointment ? (
                                                <Text color="gray.500">Past Appointment</Text>
                                            ) : (
                                                <>
                                                    <Button 
                                                        colorScheme="red" 
                                                        size="sm" 
                                                        onClick={() => handleOpenModal(appointment, 'cancel')} 
                                                        isDisabled={disableCancel}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button 
                                                        colorScheme="blue" 
                                                        size="sm" 
                                                        onClick={() => handleOpenModal(appointment, 'reschedule')} 
                                                        isDisabled={disableReschedule}
                                                    >
                                                        Reschedule
                                                    </Button>
                                                </>
                                            )}
                                        </HStack>
                                    </>
                                )}
                            </Box>
                        );
                    }) : <Text>No {title.toLowerCase()}.</Text>}
                </VStack>
            )}
            {selectedAppointment && (
                <ConfirmModal
                    isOpen={isOpen}
                    onClose={() => {
                        setFetchAppointmentsOnClose(false);
                        onClose();
                    }}
                    onConfirm={handleConfirm}
                    appointment={selectedAppointment}
                    actionType={actionType}
                    isLoading={isLoading}
                />
            )}
        </Box>
    );
};

export default AppointmentsList;
