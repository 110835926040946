import React from 'react';
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';

const Header = ({ branding }) => {
    return (
        <Box
            bg="blue.500"
            width="100%"
            height="15vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            boxShadow="md"
        >
            <Flex
                justify="center"
                align="center"
                flexDirection="column"
                maxW="100%"
                height="100%"
                pt={1} // Increase padding top here for more space above the logo
            >
                <Flex justify="center" align="center" width="100%" pt={4}>
                    <Image
                        src={branding.logoUrl}
                        alt="Logo"
                        maxH="4vh"
                        mb={10} // Optional: Add margin bottom to control space directly below the logo
                    />
                </Flex>
                <Box textAlign="center" width="100%" mb={3}> {/* Reduce margin bottom here */}
                    <Heading as="h1" size="lg" color="white">
                        {branding.headerText}
                    </Heading>
                    <Text fontSize="md" color="white">
                        {branding.subHeaderText}
                    </Text>
                </Box>
            </Flex>
        </Box>
    );
};

export default Header;
