// /src/contexts/WebAppContext.js
import React, { createContext, useState, useEffect } from 'react';

export const WebAppContext = createContext();

export const WebAppProvider = ({ children }) => {
  const [webAppInfo, setWebAppInfo] = useState({});
  const [patientType, setPatientType] = useState('');

  // Load webAppInfo from localStorage on mount
  useEffect(() => {
    const savedWebAppInfo = localStorage.getItem('webAppInfo');
    if (savedWebAppInfo) {
      setWebAppInfo(JSON.parse(savedWebAppInfo));
    }
  }, []);

  // Save webAppInfo to localStorage whenever it changes, but only if it has content
  useEffect(() => {
    if (Object.keys(webAppInfo).length > 0) {  // Check for non-empty webAppInfo
      localStorage.setItem('webAppInfo', JSON.stringify(webAppInfo));
    }
  }, [webAppInfo]);

  // Listen for changes to localStorage to synchronize webAppInfo across tabs
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'webAppInfo' && event.newValue) {
        setWebAppInfo(JSON.parse(event.newValue));
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <WebAppContext.Provider value={{ webAppInfo, setWebAppInfo, patientType, setPatientType }}>
      {children}
    </WebAppContext.Provider>
  );
};
