import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Box, VStack, Text, useToast } from '@chakra-ui/react';
import API from '../api/axiosConfig';
import ErrorAlert from '../components/ErrorAlert';
import "../styles/pages/VerifyOTP.css";

const OTP_EXPIRATION_TIME = 300; // 5 minutes in seconds

const VerifyOTP = () => {
    const [otp, setOtp] = useState('');
    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [countdown, setCountdown] = useState(OTP_EXPIRATION_TIME);
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        const otpToken = sessionStorage.getItem('otpToken');
        if (otpToken) {
            setToken(otpToken);
        } else {
            toast({
                title: "Session Error",
                description: "Session expired. Please try logging in again.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            navigate('/login');
        }
    }, [navigate, toast]);

    useEffect(() => {
        if (countdown > 0) {
            const timerId = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
            return () => clearInterval(timerId);
        } else {
            toast({
                title: "OTP Expired",
                description: "Your SMS Passcode has expired. Please login again to request a new one.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    }, [countdown, toast]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (value.length <= 4) {
            setOtp(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (otp.length !== 4) {
            toast({
                title: "Validation Error",
                description: "OTP must be 4 digits.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        setIsLoading(true);
        setError('');
        try {
            const response = await API.post('/web/otp/verify', { otp, token }, {
                headers: { 'Authorization': `Bearer ${sessionStorage.getItem('tempToken')}` }
            });
            if (response.data.message === 'Authentication successful') {
                toast({
                    title: "Success",
                    description: "Authentication successful!",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
              
                navigate('/dashboard');
            } else {
                throw new Error(response.data.message || "Failed to verify OTP.");
            }
        } catch (error) {
            setError(error.message || "An error occurred while verifying the OTP.");
        } finally {
            setIsLoading(false);
        }
    };

    const formatCountdown = () => {
        const minutes = Math.floor(countdown / 60);
        const seconds = countdown % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleBackToLogin = () => {
        const uuid = localStorage.getItem('UUID');
        console.log("UUID from localStorage:", uuid);

        // Delete the auth cookie by setting an expired date
        document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        // Clear sessionStorage
        sessionStorage.clear();

        // Redirect to the URL with the UUID
        if (uuid) {
            window.location.href = `/${uuid}`;
        } else {
            navigate('/login');
        }
    };

    return (
        <VStack className="verify-otp-container" spacing={6} align="center" justify="center">
            {error && <ErrorAlert title="Verification Error" description={error} onClose={() => setError('')} />}
            <Box className="otp-box">
                <Text className="otp-text">
                    A 4-digit passcode has been sent via SMS to your mobile phone. Please enter this code here.
                </Text>
                <Text fontSize="lg" color="gray.600" marginBottom="1rem">
                    Time remaining: {formatCountdown()}
                </Text>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={6}>
                        <Input
                            type="text"
                            value={otp}
                            onChange={handleInputChange}
                            placeholder="Enter 4-Digits"
                            isRequired
                            focusBorderColor="blue.500"
                            isDisabled={isLoading}
                            className="otp-input"
                            maxLength={4}
                            textAlign="center"
                        />
                        <Button
                            type="submit"
                            colorScheme="blue"
                            isLoading={isLoading}
                            loadingText="Verifying"
                            className="otp-button"
                        >
                            Submit
                        </Button>
                    </VStack>
                </form>
            </Box>
            {/* Back Button below the entire container */}
            <Text
                as="a"
                onClick={handleBackToLogin}
                cursor="pointer"
                mt={4}
                color="black"
                fontWeight="medium"
                _hover={{ textDecoration: "underline" }}
            >
                Back to Login
            </Text>

        </VStack>
    );
};

export default VerifyOTP;
