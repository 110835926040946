import React, { useState, useEffect } from 'react';
import { Center, Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, SimpleGrid, VStack, HStack, Box } from '@chakra-ui/react';
import '../styles/components/AppointmentBookingModal.css';

const AppointmentBookingModal = ({ isOpen, onClose, _pos, provider, appointment, onConfirmBooking, isBooking, bookingError, selectedAppointment, isPlanned }) => {
    const [selectedTime, setSelectedTime] = useState(null);
    const [isConfirmMode, setIsConfirmMode] = useState(false);
    const [rescheduling, setRescheduling] = useState(false);

    useEffect(() => {
        if (isBooking) {
            setRescheduling(!!selectedAppointment && !isPlanned); // Only reschedule if not planned
        }
    }, [isBooking, selectedAppointment, isPlanned]);

    const handleTimeSelect = (time) => {
        setSelectedTime(time);
    };

    const handleBook = () => {
        setIsConfirmMode(true);
    };

    const handleConfirm = async () => {
        await onConfirmBooking(_pos, appointment.date, selectedTime);
        setRescheduling(false);
        onClose();
    };

    const handleCancelConfirm = () => {
        setIsConfirmMode(false);
        setSelectedTime(null);
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    if (!appointment || !appointment.date) {
        return null;
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent className="modal-content">
                <ModalHeader>{rescheduling || isBooking
                    ? "Please wait..."
                    : isConfirmMode
                        ? (selectedAppointment && !isPlanned ? "Confirm Reschedule" : "Confirm Booking")
                        : provider.FullName}</ModalHeader>
                <ModalCloseButton className="close-button" isDisabled={rescheduling || isBooking} />
                <ModalBody>
                    {(rescheduling || isBooking) ? (
                        <Center className="loading-center">
                            <Spinner />
                            <Text ml={4}>{rescheduling ? 'Rescheduling appointment...' : 'Booking appointment...'}</Text>
                        </Center>
                    ) : isConfirmMode ? (
                        <VStack spacing={4} align="stretch">
                            {!isPlanned && selectedAppointment && (
                                <>
                                    <Text fontWeight="bold">Current Appointment:</Text>
                                    <Box className="appointment-box">
                                        <Text><strong>Provider:</strong> {selectedAppointment.ProvFullName}</Text>
                                        <Text><strong>Date:</strong> {formatDate(selectedAppointment.AptDateTime)}</Text>
                                        <Text><strong>Time:</strong> {new Date(selectedAppointment.AptDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Text>
                                    </Box>
                                </>
                            )}
                            <Text fontWeight="bold">{selectedAppointment && !isPlanned ? 'New Appointment' : 'Book Appointment'}:</Text>
                            <Box className="appointment-box">
                                <Text><strong>Provider:</strong> {provider.FullName}</Text>
                                <Text><strong>Date:</strong> {formatDate(appointment.date)}</Text>
                                <Text><strong>Time:</strong> {selectedTime}</Text>
                            </Box>
                            <Text>
                                {!isPlanned && selectedAppointment 
                                    ? `Do you want to reschedule your appointment from ${formatDate(selectedAppointment.AptDateTime)} at ${new Date(selectedAppointment.AptDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} with ${selectedAppointment.ProvFullName} to ${formatDate(appointment.date)} at ${selectedTime} with ${provider.FullName}?`
                                    : `Do you want to book this appointment on ${formatDate(appointment.date)} at ${selectedTime} with ${provider.FullName}?`}
                            </Text>
                        </VStack>
                    ) : (
                        <VStack spacing={4} align="stretch">
                            <Text><strong>Specialty:</strong> {provider.SpecialtyName}</Text>
                            <Text><strong>Date:</strong> {formatDate(appointment.date)}</Text>

                            <Text>Select your preferred appointment time:</Text>


                            <SimpleGrid columns={[2, 3, 4, 5]} spacing={4} mt={1}>
                                {appointment.time.map((slot, index) => (
                                    <Button
                                        key={index}
                                        className={`time-slot-button ${selectedTime === slot ? 'selected' : ''}`}
                                        onClick={() => handleTimeSelect(slot)}
                                    >
                                        <Text fontSize="xs">{slot}</Text>
                                    </Button>
                                ))}
                            </SimpleGrid>
                        </VStack>
                    )}
                    {bookingError && <Text color="red.500">{bookingError}</Text>}
                </ModalBody>
                <ModalFooter>
                    {(rescheduling || isBooking) ? null : isConfirmMode ? (
                        <HStack spacing={2}>
                            <Button colorScheme="blue" onClick={handleConfirm}>
                                Yes
                            </Button>
                            <Button onClick={handleCancelConfirm}>
                                No
                            </Button>
                        </HStack>
                    ) : (
                        <HStack spacing={2}>
                            <Button 
                                className="book-button" // New Book button class
                                colorScheme={selectedAppointment && !isPlanned ? "blue" : "green"} 
                                onClick={handleBook} 
                                isDisabled={!selectedTime}>
                                {selectedAppointment && !isPlanned ? 'Reschedule' : 'Book'}
                            </Button>
                            <Button 
                                className="close-button-custom" // New Close button class
                                onClick={onClose}>
                                Close
                            </Button>
                        </HStack>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AppointmentBookingModal;
