import React from 'react';
import { Box, Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton } from '@chakra-ui/react';
import '../styles/components/ErrorAlert.css'; // Import the external CSS file

const ErrorAlert = ({ title, description, onClose }) => {
    return (
        <Alert status="error" className="error-alert">
            <AlertIcon />
            <Box className="alert-box">
                <AlertTitle>{title}</AlertTitle>
                <AlertDescription>{description}</AlertDescription>
            </Box>
            <CloseButton className="close-button" onClick={onClose} />
        </Alert>
    );
};

export default ErrorAlert;
