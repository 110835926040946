import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button, Input, Checkbox, VStack, Box, Text, Link, Flex, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { FaUser, FaCalendarAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import API from '../api/axiosConfig';
import { WebAppContext } from '../contexts/WebAppContext';
import ErrorAlert from '../components/ErrorAlert';
import "../styles/components/LoginForm.css";

const LoginForm = () => {
    const navigate = useNavigate();
    const { webAppInfo, patientType, setPatientType } = useContext(WebAppContext);
    const [error, setError] = useState('');
    
    const PatientTypeCard = ({ type, label }) => (
        <Box
            className="patient-type-card"
            onClick={() => setPatientType(type)}
            role="button"
            aria-pressed={patientType === type ? "true" : "false"}
        >
            <Text fontSize="xl">{label}</Text>
        </Box>
    );
    
    if (!patientType) {
        return (
            <Flex 
                justify="center" 
                align="center" 
                height="65vh"
            >
                <VStack spacing={6} textAlign="center">
                    <Box className="welcome-text">
                        <Text className="welcome-title">Welcome!</Text>
                        <Text className="patient-prompt">
                            Please tell us if you're a new or returning patient:
                        </Text>
                    </Box>
                    <Flex flexDirection={{ base: 'column', md: 'row' }} spacing={4}>
                        <PatientTypeCard type="new" label="New Patient" />
                        <PatientTypeCard type="existing" label="Returning Patient" />
                    </Flex>
                </VStack>
            </Flex>
        );
    }

    return (
        <Flex height="100vh" align="center" justify="center">
            <Box className="login-form-container" p={4}>
                {/* Conditional Header based on Patient Type */}
                <Text fontSize="2xl" fontWeight="bold" mb={2} px={4}>
                    {patientType === 'new' ? 'Sign Up' : 'Welcome Back'}
                </Text>

                {/* Conditional Text based on Patient Type */}
                <Text fontSize="lg" mb={4} px={4}>
                    {patientType === 'new' ? (
                        <>
                            Please fill in your credentials to sign up. If you are a returning patient,{' '}
                            <Link color="blue.500" onClick={() => setPatientType('existing')}>click here</Link>.
                        </>
                    ) : (
                        <>
                            Please fill in your credentials to sign in. If you are a new patient,{' '}
                            <Link color="blue.500" onClick={() => setPatientType('new')}>click here</Link>.
                        </>
                    )}
                </Text>

                {error && <ErrorAlert title="Form Submission Error" description={error} onClose={() => setError('')} />}
                
                <Formik
                    initialValues={{
                        firstName: '', lastName: '', dob: '', phone: '', email: '', agreeToPrivacy: false
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.firstName) errors.firstName = 'First Name is required';
                        if (!values.lastName) errors.lastName = 'Last Name is required';
                        if (!values.dob) errors.dob = 'Date of Birth is required';
                        if (!values.phone) errors.phone = 'Phone number is required';
                        if (!values.email) errors.email = 'Email is required';
                        if (!values.agreeToPrivacy) errors.agreeToPrivacy = 'You must agree to the Terms and Privacy Policy';
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        const tempToken = sessionStorage.getItem('tempToken');
                        if (!tempToken) {
                            setError('Temporary token not found.');
                            setSubmitting(false);
                            return;
                        }

                        API.post('/web/patient/check', {
                            fname: values.firstName,
                            lname: values.lastName,
                            mobile: values.phone,
                            birthdate: values.dob,
                            returning: patientType === 'existing' ? 'true' : 'false'
                        }, {
                            headers: {
                                'Authorization': `Bearer ${tempToken}`,
                            }
                        })
                        .then(response => {
                            if (response.data.token) {
                                sessionStorage.setItem('otpToken', response.data.token);
                            }
                            setSubmitting(false);
                            navigate('/verify');
                        })
                        .catch(error => {
                            console.error('Error during form submission:', error);
                            setError(error.response?.data?.message || 'An unexpected error occurred');
                            setSubmitting(false);
                        });                    
                    }}
                >
                    {({ handleChange, handleSubmit, isSubmitting, errors, touched }) => (
                        <Form onSubmit={handleSubmit}>
                            <VStack spacing={4} px={4}>
                                <Box>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents="none" children={<FaUser color="gray.300" />} />
                                        <Input name="firstName" onChange={handleChange} placeholder="First Name" aria-label="First Name" />
                                    </InputGroup>
                                    {errors.firstName && touched.firstName && <Text color="red.500">{errors.firstName}</Text>}
                                </Box>
                                <Box>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents="none" children={<FaUser color="gray.300" />} />
                                        <Input name="lastName" onChange={handleChange} placeholder="Last Name" aria-label="Last Name" />
                                    </InputGroup>
                                    {errors.lastName && touched.lastName && <Text color="red.500">{errors.lastName}</Text>}
                                </Box>
                                <Box>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents="none" children={<FaCalendarAlt color="gray.300" />} />
                                        <Input name="dob" type="date" onChange={handleChange} placeholder="Date of Birth" aria-label="Date of Birth" />
                                    </InputGroup>
                                    {errors.dob && touched.dob && <Text color="red.500">{errors.dob}</Text>}
                                </Box>
                                <Box>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents="none" children={<FaPhone color="gray.300" />} />
                                        <Input name="phone" onChange={handleChange} placeholder="Phone #" aria-label="Phone Number" />
                                    </InputGroup>
                                    {errors.phone && touched.phone && <Text color="red.500">{errors.phone}</Text>}
                                </Box>
                                <Box>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents="none" children={<FaEnvelope color="gray.300" />} />
                                        <Input name="email" onChange={handleChange} placeholder="Email" aria-label="Email" />
                                    </InputGroup>
                                    {errors.email && touched.email && <Text color="red.500">{errors.email}</Text>}
                                </Box>
                                <Box>
                                    <Checkbox 
                                        name="agreeToPrivacy" 
                                        onChange={handleChange} 
                                        aria-label="Agree to Privacy Terms" 
                                        className="agree-to-privacy" 
                                    >
                                    Agree to <a href={webAppInfo.legalInfo?.termsPrivacy.url || "#"} className="privacy-link" target="_blank" rel="noreferrer">
                                    {webAppInfo.legalInfo?.termsPrivacy.displayText || 'Privacy Terms'}
                                    </a>
                                    </Checkbox>
                                    {errors.agreeToPrivacy && touched.agreeToPrivacy && (
                                        <Text color="red.500">{errors.agreeToPrivacy}</Text>
                                    )}
                                    </Box>
                                <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
                                    {patientType === 'new' ? 'Sign Up' : 'Sign In'}
                                </Button>
                            </VStack>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Flex>
    );
};

export default LoginForm;
