import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import "../styles/components/BookingBreadcrumb.css"; // Import the relevant styles

const BookingBreadcrumb = ({ category, typeName, onResetType, onResetCategory, compactView = false }) => {
    return (
        <Box className={`breadcrumb-container ${compactView ? 'compact-view' : ''}`} overflowX="auto" whiteSpace="nowrap">
            <Breadcrumb separator=">" spacing="8px">
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to="#" onClick={onResetCategory}>
                        Categories
                    </BreadcrumbLink>
                </BreadcrumbItem>
                {category && (
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="#" onClick={onResetType}>
                            {category}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                )}
                {typeName && (
                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink as={Link} to="#">
                            {typeName}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                )}
            </Breadcrumb>
        </Box>
    );
};

export default BookingBreadcrumb;
